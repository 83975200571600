<script setup lang="ts">
import { useBranchStore } from '~/store/branches'
import RButton from '~/components/rButton.vue'
import RequestLimits from '~/components/branch/RequestLimits.client.vue'

const {
  $api: { branchService },
} = useNuxtApp()
const route = useRoute()
const links = ref([
  {
    name: 'Аналитика',
    icon: 'uil:analytics',
    route: 'analytics',
    disabled: false,
  },
  {
    name: 'Запросы отзывов',
    icon: 'uil:user-plus',
    route: 'requests',
    disabled: false,
  },
  { name: 'Отзывы', icon: 'uil:star', route: 'reviews', disabled: false },
  {
    name: 'Сотрудники',
    icon: 'uil:users-alt',
    route: 'staff',
    disabled: false,
  },
  // {
  //   name: 'Лендинг',
  //   icon: 'material-symbols:phone-android-outline',
  //   route: 'landings',
  //   disabled: false,
  // },
  { name: 'Настройки', icon: 'uil:cog', route: 'settings', disabled: false },
])
const store = useBranchStore()
function redirect() {
  navigateTo(`/branches/${route.params.id}/add/${store.$state.branch?.slug}/${store.$state.branch?.hash}`)
}
const limits = ref(null)

onMounted(async () => {
  limits.value = await branchService.requestsStats(store.$state.branch?.hash)
})
</script>

<template>
  <div class="bg-milky-100 min-h-full min-w-[200px] w-[200px]">
    <div>
      <ul class="gap-4 flex-col flex">
        <li
          v-for="link in links"
          :key="link.name"
          class="text-blue-500 hover:text-blue-600 text-primary-small"
          :class="[link.disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
        >
          <NuxtLink
            no-prefetch
            active-class="text-black hover:text-black"
            class="ml-4 flex flex-nowrap flex-row gap-3 items-center text-[14px] duration-150"
            :class="[
              link.disabled
                ? 'opacity-50 text-blue-500 pointer-events-none'
                : '',
            ]"
            :to="`/branches/${route.params.id}/${link.route}`"
          >
            <Icon :name="`${link.icon}`" width="1.3em" height="1.3em" />
            <span>{{ link.name }}</span>
          </NuxtLink>
        </li>
        <div class="ml-4 flex flex-col gap-2 p-0 justify-center items-start">
          <RButton
            font-styles="text-m-primary-small"
            class="p-0"
            @click="redirect"
          >
            Запросить отзыв
          </RButton>

          <ClientOnly>
            <RequestLimits type="aside" />
          </ClientOnly>
        </div>
      </ul>
    </div>
  </div>
</template>

<style scoped>
@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show {
  opacity: 0;
  animation: show 200ms ease-in-out;
  animation-fill-mode: forwards;
}
</style>
